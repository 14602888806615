// src/contexts/SocketContext.js

import React, { createContext, useEffect } from 'react';
import socket from '../socket';

export const SocketContext = createContext(socket);

export const SocketProvider = ({ children }) => {
    useEffect(() => {
        if (!socket.connected) {
            socket.connect();
        }
    }, []);

    return (
        <SocketContext.Provider value={socket}>
            {children}
        </SocketContext.Provider>
    );
};