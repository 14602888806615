// src/socket.js

import { io } from 'socket.io-client';

const SOCKET_URL = 'https://inviders.kitezhgrad.fun';

const socket = io(SOCKET_URL, {
    path: '/socket.io/',
    transports: ['websocket'],
    withCredentials: true,
});

export default socket;
