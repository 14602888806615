// src/components/GameRooms/RoomList/RoomList.js

import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRooms, joinRoom } from '../../../actions/gameRoomActions';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import texts from '../../../utils/texts';
import { useNavigate } from 'react-router-dom';
import { SocketContext } from '../../../contexts/SocketContext';
import './RoomList.css';

const RoomList = () => {
    const socket = useContext(SocketContext);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {rooms, loading, error} = useSelector((state) => state.gameRooms);

    const [selectedRoomId, setSelectedRoomId] = useState(null);
    const [password, setPassword] = useState('');
    const [joinError, setJoinError] = useState('');
    const [isJoining, setIsJoining] = useState(false);

    useEffect(() => {
        if (!socket.connected) {
            socket.connect();
        }

        // TODO: remove
        const handleUpdateRoomList = (updatedRooms) => {
            console.log('Получен обновленный список комнат:', updatedRooms);
            dispatch({ type: 'FETCH_ROOMS_SUCCESS', payload: updatedRooms });
        };

        const handleSocketError = (err) => {
            console.error('Socket error:', err);
        };

        socket.on('updateRoomList', handleUpdateRoomList);
        socket.on('error', handleSocketError);

        // Запрашиваем список комнат
        socket.emit('requestRoomList');

        return () => {
            socket.off('updateRoomList', handleUpdateRoomList);
            socket.off('error', handleSocketError);
        };
    }, [dispatch, socket]);

    useEffect(() => {
        dispatch(fetchRooms());
    }, [dispatch]);

    const handleJoin = async (room) => {
        if (room.is_open) {
            try {
                setIsJoining(true);
                await dispatch(joinRoom(room.room_id));
            } catch (err) {
                setJoinError(err.message || texts.errors.roomNotFound);
            } finally {
                setIsJoining(false);
            }
        } else {
            setSelectedRoomId(room.room_id);
            setJoinError('');
        }
    };

    const handleJoinWithPassword = async (roomId) => {
        try {
            setIsJoining(true);
            await dispatch(joinRoom(roomId, password));
            setSelectedRoomId(null);
            setPassword('');
        } catch (err) {
            setJoinError(err.message || texts.errors.incorrectPassword);
        } finally {
            setIsJoining(false);
        }
    };

    const handleBack = () => {
        navigate(-1);
    };

    if (loading) {
        return <LoadingSpinner/>;
    }

    return (
        <div className="room-list">
            <h1 className="room-list__title">{texts.roomList.title}</h1>
            {error && <p className="room-list__error">{error}</p>}
            {joinError && <p className="room-list__error">{joinError}</p>}
            <ul className="room-list__list">
                {rooms.length === 0 && <p>{texts.roomList.noRooms}</p>}
                {rooms.map((room) => (
                    <li key={room.room_id} className="room-list__item">
                        <div className="room-list__info">
                            <p className="room-list__name">{room.room_name}</p>
                            <p className="room-list__details">
                                {texts.roomDetail.mapSize}: {room.map_size}x{room.map_size},{' '}
                                {texts.roomDetail.movesPerPlayer}: {room.moves_per_player}
                            </p>
                        </div>
                        <button
                            className="room-list__join-button index__button"
                            onClick={() => handleJoin(room)}
                        >
                            {texts.roomList.joinButton}
                        </button>
                        {!room.is_open && selectedRoomId === room.room_id && (
                            <div className="room-list__password">
                                <input
                                    type="password"
                                    className="room-list__password-input"
                                    placeholder={texts.roomList.enterPassword}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <button
                                    className="room-list__join-button index__button"
                                    onClick={() => handleJoinWithPassword(room.room_id)}
                                    disabled={isJoining}
                                >
                                    {texts.roomList.enterPasswordButton}
                                </button>
                            </div>
                        )}
                    </li>
                ))}
            </ul>
            <div className="room-list__back-button">
                <button className="index__button" onClick={handleBack}>
                    Назад
                </button>
            </div>
        </div>
    );
};

export default RoomList;