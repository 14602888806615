// src/components/GameSession/TopPanel.js

import React, { useState } from 'react';
import './TopPanel.css';

const TopPanel = ({ resources, onLeave }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen((prev) => !prev);
    };

    return (
        <div className="top-panel">
            <div className="top-panel__resources">
                <span>🌲 {resources.wood}</span>
                <span>🥔 {resources.food}</span>
            </div>
            <div className="top-panel__menu">
                <button className="top-panel__menu-button" onClick={toggleMenu}>
                    ...
                </button>
                {isMenuOpen && (
                    <div className="top-panel__dropdown">
                        <button className="top-panel__dropdown-item" onClick={onLeave}>
                            Выйти
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TopPanel;
