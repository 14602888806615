// src/components/GameSession/Cell.js

import React from 'react';
import CELLS from '../../utils/cells';
import './Cell.css';

const Cell = ({ cell }) => {
    const { cell_type, owner_id, coordinate_x, coordinate_y, is_image } = cell;
    const cellConfig = CELLS[cell_type] || CELLS['grass'];

    const style = {
        backgroundColor: cellConfig.color,
        position: 'relative',
    };

    return (
        <div className="cell" style={style}>
            {owner_id && (
                <span className="cell__owner">
                    {/* Можно отобразить иконку или инициалы владельца */}
                    {owner_id}
                </span>
            )}
            {/* Логика отображения изображений может быть добавлена позже */}
        </div>
    );
};

export default Cell;