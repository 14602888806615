// src/components/GameRooms/RoomDetail/Chat.js

import React, { useEffect } from 'react';
import ChatMessage from './ChatMessage';
import './Chat.css';

const Chat = ({
                  messages,
                  newMessage,
                  onSendMessage,
                  onChangeMessage,
                  messagesEndRef,
              }) => {
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            onSendMessage();
        }
    };

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({behavior: 'smooth'});
        }
    }, [messages, messagesEndRef]);

    return (
        <div className="chat">
            <div className="chat__messages">
                {messages.map((msg) => (
                    <ChatMessage key={msg.id} message={msg} />
                ))}
                <div ref={messagesEndRef} />
            </div>
            <div className="chat__input">
                <input
                    type="text"
                    className="chat__text-input"
                    value={newMessage}
                    onChange={(e) => onChangeMessage(e.target.value)}
                    placeholder="Введите сообщение..."
                    onKeyDown={handleKeyDown}
                />
                <button className="chat__send-button" onClick={onSendMessage}>
                    &gt;
                </button>
            </div>
        </div>
    );
};

export default Chat;