// src/reducers/index.js

import {combineReducers} from 'redux';
import currentUserReducer from './currentUserReducer';
import viewedUserReducer from './viewedUserReducer';
import gameRoomReducer from './gameRoomReducer';
import gameSessionReducer from './gameSessionReducer';

const rootReducer = combineReducers({
    currentUser: currentUserReducer,
    viewedUser: viewedUserReducer,
    gameRooms: gameRoomReducer,
    gameSession: gameSessionReducer,
});

export default rootReducer;
