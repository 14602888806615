import React from 'react';
import {Link} from 'react-router-dom';
import texts from '../../utils/texts';

import './MainMenu.css';

const MainMenu = () => {
    return (
        <div className="main-menu">
            <img src="/assets/images/app/snake.png" alt="Snake" className="main-menu__image"/>

            <h1 className="main-menu__title">{texts.mainMenu.title}</h1>
            <ul className="main-menu__list">
                <li className="main-menu__item">
                    <Link to="/game-rooms/create" className="main-menu__link">{texts.mainMenu.createGame}</Link>
                </li>
                <li className="main-menu__item">
                    <Link to="/game-rooms/list" className="main-menu__link">{texts.mainMenu.findGame}</Link>
                </li>
                <li className="main-menu__item">
                    <Link to="/mainmenu" className="main-menu__link">{texts.mainMenu.profile}</Link>
                </li>
            </ul>
        </div>
    );
};

export default MainMenu;