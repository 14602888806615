// src/components/GameRooms/RoomDetail/ChatMessage.js

import React from 'react';
import './ChatMessage.css';

const ChatMessage = ({ message }) => {
    return (
        <div className="chat-message">
            <strong>{message.sender_name}:</strong> {message.message}
        </div>
    );
};

export default ChatMessage;
