// src/reducers/gameRoomReducer.js

import {
    CREATE_ROOM_REQUEST,
    CREATE_ROOM_SUCCESS,
    CREATE_ROOM_FAILURE,
    FETCH_ROOMS_REQUEST,
    FETCH_ROOMS_SUCCESS,
    FETCH_ROOMS_FAILURE,
    JOIN_ROOM_REQUEST,
    JOIN_ROOM_SUCCESS,
    JOIN_ROOM_FAILURE,
    FETCH_ROOM_DETAILS_REQUEST,
    FETCH_ROOM_DETAILS_SUCCESS,
    FETCH_ROOM_DETAILS_FAILURE,
    LEAVE_ROOM_REQUEST,
    LEAVE_ROOM_SUCCESS,
    LEAVE_ROOM_FAILURE,
    UPDATE_READY_STATUS_REQUEST,
    UPDATE_READY_STATUS_SUCCESS,
    UPDATE_READY_STATUS_FAILURE,
    KICK_PLAYER_REQUEST,
    KICK_PLAYER_SUCCESS,
    KICK_PLAYER_FAILURE,
    START_GAME_REQUEST,
    START_GAME_SUCCESS,
    START_GAME_FAILURE,
    PLAYER_JOINED,
    PLAYER_LEFT,
    PLAYER_KICKED,
    USER_KICKED,
} from '../constants/gameRoomConstants';

const initialState = {
    loading: false,
    rooms: [],
    currentRoom: null,
    error: null,
};

const gameRoomReducer = (state = initialState, action) => {
    console.log('GameRoom Reducer Action:', action.type);
    console.log('Payload:', action.payload);

    switch (action.type) {
        case CREATE_ROOM_REQUEST:
        case FETCH_ROOMS_REQUEST:
        case JOIN_ROOM_REQUEST:
        case FETCH_ROOM_DETAILS_REQUEST:
        case UPDATE_READY_STATUS_REQUEST:
        case KICK_PLAYER_REQUEST:
        case START_GAME_REQUEST:
            return {...state, loading: true, error: null};

        case CREATE_ROOM_SUCCESS:
            return {...state, loading: false, currentRoom: action.payload};

        case FETCH_ROOMS_SUCCESS:
            return {...state, loading: false, rooms: action.payload};

        case JOIN_ROOM_SUCCESS:
            return {...state, loading: false, currentRoom: action.payload};

        case FETCH_ROOM_DETAILS_SUCCESS:
            return {...state, loading: false, currentRoom: action.payload};

        case LEAVE_ROOM_SUCCESS:
            return {...state, loading: false, currentRoom: null};

        case UPDATE_READY_STATUS_SUCCESS:
            if (state.currentRoom) {
                const updatedPlayers = state.currentRoom.players.map((player) =>
                    player.chat_id === action.payload.player.chat_id
                        ? {...player, is_ready: action.payload.player.is_ready}
                        : player
                );
                return {
                    ...state,
                    loading: false,
                    currentRoom: {...state.currentRoom, players: updatedPlayers},
                };
            }
            return {...state, loading: false};

        case KICK_PLAYER_SUCCESS:
        case START_GAME_SUCCESS:
            return {...state, loading: false, currentRoom: action.payload};

        case CREATE_ROOM_FAILURE:
        case FETCH_ROOMS_FAILURE:
        case JOIN_ROOM_FAILURE:
        case FETCH_ROOM_DETAILS_FAILURE:
        case LEAVE_ROOM_FAILURE:
        case UPDATE_READY_STATUS_FAILURE:
        case KICK_PLAYER_FAILURE:
        case START_GAME_FAILURE:
            return {...state, loading: false, error: action.payload};

        case PLAYER_JOINED:
            if (!state.currentRoom || !Array.isArray(state.currentRoom.players)) {
                return state;
            }
            return {
                ...state,
                currentRoom: {
                    ...state.currentRoom,
                    players: [...state.currentRoom.players, action.payload],
                },
            };

        case PLAYER_LEFT:
            if (!state.currentRoom || !Array.isArray(state.currentRoom.players)) {
                return state;
            }
            return {
                ...state,
                currentRoom: {
                    ...state.currentRoom,
                    players: state.currentRoom.players.filter(p => p.chat_id !== action.payload.chat_id),
                },
            };

        case PLAYER_KICKED:
            if (!state.currentRoom || !Array.isArray(state.currentRoom.players)) {
                return state;
            }
            return {
                ...state,
                currentRoom: {
                    ...state.currentRoom,
                    players: state.currentRoom.players.filter(p => p.chat_id !== action.payload.chat_id),
                },
            };

        case USER_KICKED:
            return {
                ...state,
                loading: false,
                currentRoom: null,
            };

        default:
            return state;
    }
};

export default gameRoomReducer;