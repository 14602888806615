// src/index.js

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {Provider} from 'react-redux';
import {BrowserRouter as Router} from 'react-router-dom';
import store from './store';
import App from './App';
import {SocketProvider} from "./contexts/SocketContext";

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <Router>
                <SocketProvider>
                    <App />
                </SocketProvider>
            </Router>
        </Provider>
    </React.StrictMode>
);