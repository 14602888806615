// src/components/GameRooms/RoomDetail/Controls.js

import React from 'react';
import texts from '../../../utils/texts';
import './Controls.css';

const Controls = ({
                      isHost,
                      allReady,
                      userIsReady,
                      onToggleReady,
                      onStartGame,
                      onLeaveRoom,
                  }) => {
    return (
        <div className="controls">
            <button
                className="controls__ready-button index__button"
                onClick={onToggleReady}
                disabled={false}
            >
                {userIsReady ? texts.roomDetail.notReadyButton : texts.roomDetail.readyButton}
            </button>
            {isHost && (
                <button
                    className={`controls__start-button index__button ${!allReady ? 'index__button--disabled' : ''}`}
                    onClick={onStartGame}
                    disabled={!allReady}
                >
                    {texts.roomDetail.startGameButton}
                </button>
            )}
            <button className="controls__leave-button index__button" onClick={onLeaveRoom}>
                {texts.roomDetail.exit}
            </button>
        </div>
    );
};

export default Controls;
