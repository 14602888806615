// src/reducers/currentUserReducer.js

const initialState = {
    loading: false,
    user: null,
    error: null,
};

const currentUserReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGIN_REQUEST':
        case 'FETCH_USER_STATUS_REQUEST':
        case 'UPDATE_USER_REQUEST':
        case 'UPDATE_IS_BOT_BANNED_REQUEST':
            return { ...state, loading: true, error: null };

        case 'LOGIN_SUCCESS':
        case 'FETCH_USER_STATUS_SUCCESS':
        case 'UPDATE_USER_SUCCESS':
            return { ...state, loading: false, user: action.payload };

        case 'UPDATE_IS_BOT_BANNED_SUCCESS':
            return {
                ...state,
                loading: false,
                user: { ...state.user, is_bot_banned: action.payload.is_bot_banned }
            };

        case 'LOGIN_FAILURE':
        case 'FETCH_USER_STATUS_FAILURE':
        case 'UPDATE_USER_FAILURE':
        case 'UPDATE_IS_BOT_BANNED_FAILURE':
            return { ...state, loading: false, error: action.payload };

        default:
            return state;
    }
};

export default currentUserReducer;