// src/components/GameSession/ActionButtons.js

import React from 'react';
import './ActionButtons.css';

const ActionButtons = () => {
    return (
        <div className="action-buttons">
            <div className="action-buttons__button">
                {/* Пустая иконка */}
                <div className="action-buttons__icon">🔍</div>
                <span className="action-buttons__label">Исследовать</span>
            </div>
            <div className="action-buttons__button">
                <div className="action-buttons__icon">⚔️</div>
                <span className="action-buttons__label">Атаковать</span>
            </div>
            <div className="action-buttons__button">
                <div className="action-buttons__icon">🏠</div>
                <span className="action-buttons__label">Строить</span>
            </div>
        </div>
    );
};

export default ActionButtons;
