// src/reducers/viewedUserReducer.js

const initialState = {
    loading: false,
    user: null,
    error: null,
};

const viewedUserReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_VIEWED_USER_REQUEST':
            return { ...state, loading: true, error: null };
        case 'FETCH_VIEWED_USER_SUCCESS':
            return { ...state, loading: false, user: action.payload };
        case 'FETCH_VIEWED_USER_FAILURE':
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

export default viewedUserReducer;
