// src/components/GameSession/GameField.js

import React from 'react';
import Cell from './Cell';
import './GameField.css';

const GameField = ({ cells }) => {
    const cellSize = 40;
    const gridStyle = {
        display: 'grid',
        gridTemplateColumns: `repeat(10, ${cellSize}px)`,
        gridTemplateRows: `repeat(10, ${cellSize}px)`,
        gap: '0px',
        width: `${cellSize * 10}px`,
        height: `${cellSize * 10}px`,
    };

    return (
        <div className="game-field" style={gridStyle}>
            {cells.map((cell) => (
                <Cell key={`${cell.coordinate_x}-${cell.coordinate_y}`} cell={cell} />
            ))}
        </div>
    );
};

export default GameField;
