// src/App.js

import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Route, Routes, Navigate, useNavigate, useLocation} from 'react-router-dom';
import {fetchUserStatus} from './actions/userActions';

import LoadingSpinner from './components/LoadingSpinner/LoadingSpinner';
import MainMenu from './components/MainMenu/MainMenu';
import Login from './components/Login/Login';
import Registration from './components/Login/Registration';
import CreateRoom from './components/GameRooms/CreateRoom/CreateRoom';
import RoomList from './components/GameRooms/RoomList/RoomList';
import RoomDetail from './components/GameRooms/RoomDetail/RoomDetail';
import GameSessionPage from "./components/GameSession/GameSessionPage";

const App = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const [isTelegram, setIsTelegram] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const currentUserState = useSelector((state) => state.currentUser);
    const {user, loading: userLoading} = currentUserState;

    // Инициализация приложения
    useEffect(() => {
        const initializeApp = async () => {
            const tg = window.Telegram ? window.Telegram.WebApp : null;

            if (!tg?.initDataUnsafe?.user) {
                // Не в Telegram среде, ожидаем логин через username и password
                setIsTelegram(false);
                setIsLoading(false);
                return;
            }

            setIsTelegram(true);

            const chatId = tg.initDataUnsafe.user.id;
            console.log('Получен chatId из Telegram:', chatId);

            try {
                await dispatch(fetchUserStatus(chatId));
            } catch (error) {
                console.error('Ошибка при инициализации приложения:', error);
            }

            try {
                await tg.expand();
                await tg.requestFullscreen({navigationUI: "hide"});
            } catch (error) {
                console.log("Ошибка при запросе полноэкранного режима:", error);
            }

            setIsLoading(false);
        };

        initializeApp();
    }, [dispatch]);

    // Перенаправление на основе статуса пользователя
    useEffect(() => {
        if (!isLoading && !userLoading) {
            if (user) {
                if (user.status === 'start' && location.pathname !== '/registration') {
                    navigate('/registration');
                } else if (user.status === 'in_room') {
                    if (user.current_room_id) {
                        navigate(`/game-rooms/${user.current_room_id}`);
                    } else {
                        navigate('/mainmenu');
                    }
                } else if (user.status === 'ready') {
                    const allowedPaths = ['/mainmenu', '/game-rooms/create', '/game-rooms/list'];
                    const isAllowed = allowedPaths.some(path => location.pathname.startsWith(path));
                    if (!isAllowed) {
                        navigate('/mainmenu');
                    }
                }
            }
        }
    }, [isLoading, user, userLoading, navigate, location.pathname]);

    if (isLoading || userLoading) {
        return <LoadingSpinner/>;
    }

    return (
        <Routes>
            <Route path="/registration" element={<Registration/>}/>
            <Route path="/mainmenu" element={<MainMenu/>}/>
            <Route path="/game/:game_id" element={<GameSessionPage />} />
            <Route path="/game-rooms/create" element={<CreateRoom/>}/>
            <Route path="/game-rooms/list" element={<RoomList/>}/>
            <Route path="/game-rooms/:room_id" element={<RoomDetail/>}/>
            <Route path="/" element={user ? <Navigate to="/mainmenu" replace/> : <Login/>}/>
            <Route path="*" element={<Navigate to="/" replace/>}/>
        </Routes>
    );
};

export default App;