// src/reducers/gameSessionReducer.js

import {
    FETCH_GAME_SESSION_REQUEST,
    FETCH_GAME_SESSION_SUCCESS,
    FETCH_GAME_SESSION_FAILURE,
    LEAVE_GAME_SESSION_REQUEST,
    LEAVE_GAME_SESSION_SUCCESS,
    LEAVE_GAME_SESSION_FAILURE,
    FETCH_GAME_MESSAGES_REQUEST,
    FETCH_GAME_MESSAGES_SUCCESS,
    FETCH_GAME_MESSAGES_FAILURE,
    SEND_GAME_MESSAGE_REQUEST,
    SEND_GAME_MESSAGE_SUCCESS,
    SEND_GAME_MESSAGE_FAILURE,
} from '../constants/gameSessionConstants';

const initialState = {
    loading: false,
    game: null,
    messages: [],
    error: null,
};

const gameSessionReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_GAME_SESSION_REQUEST:
            return { ...state, loading: true, error: null };
        case FETCH_GAME_SESSION_SUCCESS:
            return { ...state, loading: false, game: action.payload };
        case FETCH_GAME_SESSION_FAILURE:
            return { ...state, loading: false, error: action.payload };

        case LEAVE_GAME_SESSION_REQUEST:
            return { ...state, loading: true, error: null };
        case LEAVE_GAME_SESSION_SUCCESS:
            return { ...state, loading: false, game: null };
        case LEAVE_GAME_SESSION_FAILURE:
            return { ...state, loading: false, error: action.payload };

        case FETCH_GAME_MESSAGES_REQUEST:
            return { ...state, loading: true, error: null };
        case FETCH_GAME_MESSAGES_SUCCESS:
            return { ...state, loading: false, messages: action.payload };
        case FETCH_GAME_MESSAGES_FAILURE:
            return { ...state, loading: false, error: action.payload };

        case SEND_GAME_MESSAGE_REQUEST:
            return { ...state, loading: true, error: null };
        case SEND_GAME_MESSAGE_SUCCESS:
            return { ...state, loading: false, messages: [...state.messages, action.payload] };
        case SEND_GAME_MESSAGE_FAILURE:
            return { ...state, loading: false, error: action.payload };

        default:
            return state;
    }
};

export default gameSessionReducer;