// src/components/Login/Registration.js

import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {updateUser} from '../../actions/userActions';
import {useNavigate} from 'react-router-dom';
import texts from '../../utils/texts';

import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

import './Registration.css';

const Registration = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {user} = useSelector((state) => state.currentUser);

    const [gamename, setGamename] = useState('');
    const [gender, setGender] = useState('male');
    const [userPic, setUserPic] = useState('');
    const [fractionIndex, setFractionIndex] = useState(0);
    const [gamenameError, setGamenameError] = useState('');
    const [avatarError, setAvatarError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [title, setTitle] = useState('');

    useEffect(() => {
        const currentTitle = `${texts.titles.dust_slave[gender]}`;
        setTitle(currentTitle);
    }, [gender]);

    const validateGamename = (name) => {
        const gamenameRegex = /^[A-Za-zА-Яа-яЁё0-9_]{2,15}$/;
        return gamenameRegex.test(name);
    };

    const handleSave = async () => {
        let valid = true;

        if (!validateGamename(gamename)) {
            setGamenameError(texts.gamenameError);
            valid = false;
        } else {
            setGamenameError('');
        }

        if (!userPic) {
            setAvatarError(texts.avatarError || 'Пожалуйста, выберите аватар');
            valid = false;
        } else {
            setAvatarError('');
        }

        if (!valid) {
            return;
        }

        setIsSubmitting(true);

        const selectedFraction = fractions[fractionIndex];

        const updates = {
            gamename,
            gender,
            user_pic: userPic,
            fraction_name: selectedFraction.code,
            status: 'ready',
            title
        };

        try {
            await dispatch(updateUser(user.chat_id, updates));
            navigate('/mainmenu');
        } catch (error) {
            console.error('Ошибка при сохранении данных:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const avatars = ['dust_slave_1', 'dust_slave_2', 'dust_slave_3'];

    const fractions = [
        {
            name: texts.mitsanaRinsona.name,
            code: 'mitsanaRinsona',
            description: texts.mitsanaRinsona.description,
        },
        {
            name: texts.veengo.name,
            code: 'veengo',
            description: texts.veengo.description,
        },
        {
            name: texts.blackSteppeRiders.name,
            code: 'blackSteppeRiders',
            description: texts.blackSteppeRiders.description,
        },
        {
            name: texts.mkora.name,
            code: 'mkora',
            description: texts.mkora.description,
        },
    ];

    const handlePrevFraction = () => {
        setFractionIndex((prevIndex) =>
            (prevIndex - 1 + fractions.length) % fractions.length
        );
    };

    const handleNextFraction = () => {
        setFractionIndex((prevIndex) => (prevIndex + 1) % fractions.length);
    };

    const selectedFraction = fractions[fractionIndex];

    return (
        <div className="registration">
            <h1 className="index__header-h2 registration__title">{texts.registrationTitle}</h1>

            <div className="registration__field">
                <label htmlFor="gamename" className="registration__field-label">
                    {texts.createGamenameLabel}
                </label>
                <input
                    type="text"
                    id="gamename"
                    className="registration__field-input"
                    value={gamename}
                    onChange={(e) => setGamename(e.target.value)}
                    maxLength={15}
                    required
                />
                {gamenameError && (
                    <p className="registration__error">{gamenameError}</p>
                )}
            </div>

            <div className="registration__field">
                <label className="registration__field-label">
                    {texts.selectGenderLabel}
                </label>
                <p className="registration__title-display">{title}</p>
                <div className="registration__gender">
                    <button
                        type="button"
                        onClick={() => setGender('male')}
                        className={`registration__gender-button ${gender === 'male' ? 'active' : ''}`}
                        aria-pressed={gender === 'male'}
                    >
                        {texts.maleLabel}
                    </button>
                    <button
                        type="button"
                        onClick={() => setGender('female')}
                        className={`registration__gender-button ${gender === 'female' ? 'active' : ''}`}
                        aria-pressed={gender === 'female'}
                    >
                        {texts.femaleLabel}
                    </button>
                </div>
            </div>

            <div className="registration__field">
                <label className="registration__field-label">
                    {texts.selectAvatarLabel}
                </label>
                <div className="registration__avatars">
                    {avatars.map((avatar, index) => (
                        <img
                            key={index}
                            src={`/assets/images/profile/faces/${avatar}.png`}
                            alt={`Avatar ${index + 1}`}
                            className={`registration__avatar ${
                                userPic === avatar ? 'registration__avatar--selected' : ''
                            }`}
                            onClick={() => setUserPic(avatar)}
                        />
                    ))}
                </div>
            </div>

            <div className="registration__field">
                <label className="registration__field-label">
                    {texts.selectFractionLabel}
                </label>
                <p className="registration__fraction-note">{texts.fractionNote}</p>
                <div className="registration__fraction">
                    <button
                        type="button"
                        className="registration__fraction-button index__button"
                        onClick={handlePrevFraction}
                        aria-label="Предыдущая фракция"
                    >
                        &lt;
                    </button>
                    <div className="registration__fraction-content">
                        <img
                            src={`/assets/images/fractions/${selectedFraction.code}.png`}
                            alt={selectedFraction.name}
                            className="registration__fraction-image"
                        />
                        <p className="registration__fraction-name">{selectedFraction.name}</p>
                    </div>
                    <button
                        type="button"
                        className="registration__fraction-button index__button"
                        onClick={handleNextFraction}
                        aria-label="Следующая фракция"
                    >
                        &gt;
                    </button>
                </div>
                <p className="registration__fraction-description">
                    {selectedFraction.description}
                </p>
            </div>

            <button
                type="button"
                className="index__button"
                onClick={handleSave}
                disabled={isSubmitting}
            >
                {texts.saveButton}
            </button>

            {avatarError && <p className="registration__error">{avatarError}</p>}

            {isSubmitting && <LoadingSpinner/>}
        </div>
    );
};

export default Registration;