// src/components/GameRooms/CreateRoom/CreateRoom.js

import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {createRoom} from '../../../actions/gameRoomActions';
import {useNavigate} from 'react-router-dom';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import texts from '../../../utils/texts';

import './CreateRoom.css';

const CreateRoom = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {user} = useSelector((state) => state.currentUser);
    const {loading, error, currentRoom} = useSelector((state) => state.gameRooms);

    const [roomName, setRoomName] = useState('');
    const [mapSize, setMapSize] = useState(20);
    const [movesPerPlayer, setMovesPerPlayer] = useState(50);
    const [isOpen, setIsOpen] = useState(true);
    const [password, setPassword] = useState('');
    const [maxPlayers, setMaxPlayers] = useState(8);
    const [roomNameError, setRoomNameError] = useState('');

    // Проверка, находится ли пользователь уже в комнате
    useEffect(() => {
        if (user.status === 'in_room') {
            navigate(`/game-rooms/${user.current_room_id}`);
        }
    }, [user, navigate]);

    // Навигация при обновлении currentRoom
    useEffect(() => {
        if (currentRoom) {
            navigate(`/game-rooms/${currentRoom.room_id}`);
        }
    }, [currentRoom, navigate]);

    const validateRoomName = (name) => {
        return name.length > 0 && name.length <= 15;
    };

    const handleCreate = async (e) => {
        e.preventDefault();

        if (!validateRoomName(roomName)) {
            setRoomNameError('Название комнаты должно быть от 1 до 15 символов.');
            return;
        } else {
            setRoomNameError('');
        }

        const roomData = {
            room_name: roomName,
            map_size: mapSize,
            moves_per_player: movesPerPlayer,
            is_open: isOpen,
            password: isOpen ? null : password,
            max_players: maxPlayers,
            host_chat_id: user.chat_id,
        };

        try {
            await dispatch(createRoom(roomData));
            // Навигация происходит в useEffect
        } catch (err) {
            console.error('Ошибка при создании комнаты:', err);
        }
    };

    const handleBack = () => {
        navigate(-1);
    };

    return (
        <div className="create-room">
            <h1 className="create-room__title">{texts.createRoom.title}</h1>
            <form className="create-room__form" onSubmit={handleCreate}>
                <div className="create-room__field">
                    <label htmlFor="roomName" className="create-room__label">
                        {texts.createRoom.roomNameLabel}
                    </label>
                    <input
                        type="text"
                        id="roomName"
                        className="create-room__input"
                        value={roomName}
                        onChange={(e) => setRoomName(e.target.value)}
                        maxLength={15}
                        required
                    />
                    {roomNameError && <p className="create-room__error">{roomNameError}</p>}
                </div>

                <div className="create-room__field">
                    <label htmlFor="mapSize" className="create-room__label">
                        {texts.createRoom.mapSizeLabel}
                    </label>
                    <input
                        type="number"
                        id="mapSize"
                        className="create-room__input"
                        value={mapSize}
                        onChange={(e) => setMapSize(e.target.value)}
                        min={1}
                        required
                    />
                </div>

                <div className="create-room__field">
                    <label htmlFor="movesPerPlayer" className="create-room__label">
                        {texts.createRoom.movesPerPlayerLabel}
                    </label>
                    <input
                        type="number"
                        id="movesPerPlayer"
                        className="create-room__input"
                        value={movesPerPlayer}
                        onChange={(e) => setMovesPerPlayer(e.target.value)}
                        min={1}
                        required
                    />
                </div>

                <div className="create-room__field">
                    <label className="create-room__label">{texts.createRoom.isOpenLabel}</label>
                    <div className="create-room__toggle">
                        <label className="create-room__switch">
                            <input
                                type="checkbox"
                                checked={isOpen}
                                onChange={(e) => setIsOpen(e.target.checked)}
                            />
                            <span className="create-room__slider"></span>
                        </label>
                        <span>{isOpen ? 'Открытая' : 'Приватная'}</span>
                    </div>
                </div>

                {!isOpen && (
                    <div className="create-room__field">
                        <label htmlFor="password" className="create-room__label">
                            {texts.createRoom.passwordLabel}
                        </label>
                        <input
                            type="password"
                            id="password"
                            className="create-room__input"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required={!isOpen}
                        />
                    </div>
                )}

                <div className="create-room__field">
                    <label htmlFor="maxPlayers" className="create-room__label">
                        {texts.createRoom.maxPlayersLabel}
                    </label>
                    <input
                        type="number"
                        id="maxPlayers"
                        className="create-room__input"
                        value={maxPlayers}
                        onChange={(e) => setMaxPlayers(e.target.value)}
                        min={1}
                        required
                    />
                </div>

                {error && <p className="create-room__error">{error}</p>}

                <div className="create-room__buttons">
                    <button type="submit" className="index__button" disabled={loading}>
                        {texts.createRoom.createButton}
                    </button>
                    <button type="button" className="index__button" onClick={handleBack}>
                        Назад
                    </button>
                </div>

                {loading && <LoadingSpinner/>}
            </form>
        </div>
    );
};

export default CreateRoom;