// src/components/GameSession/GameSessionPage.js

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchGameSession,
    leaveGameSession,
} from '../../actions/gameSessionActions';
import { useParams, useNavigate } from 'react-router-dom';

import TopPanel from './TopPanel';
import CurrentTurn from './CurrentTurn';
import GameField from './GameField';
import ActionButtons from './ActionButtons';
import GameChat from './GameChat';

import './GameSessionPage.css';

const GameSessionPage = () => {
    const { game_id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { game, loading, error } = useSelector((state) => state.gameSession);
    const { user } = useSelector((state) => state.currentUser);

    useEffect(() => {
        if (game_id) {
            dispatch(fetchGameSession(game_id));
        }
    }, [dispatch, game_id]);

    const handleLeaveGame = () => {
        dispatch(leaveGameSession(game_id, user.chat_id));
        navigate('/mainmenu');
    };

    if (loading) {
        return <p className="game-session__loading">Загрузка...</p>;
    }

    if (error) {
        return <p className="game-session__error">{error}</p>;
    }

    if (!game) {
        return <p className="game-session__error">Игра не найдена.</p>;
    }

    return (
        <div className="game-session">
            <TopPanel resources={user.resources} onLeave={handleLeaveGame} />
            <CurrentTurn currentUser={game.current_user} nextUser={game.next_user} />
            <GameField cells={game.cells} />
            <ActionButtons />
            <GameChat gameId={game_id} />
        </div>
    );
};

export default GameSessionPage;