// src/actions/gameSessionActions.js

import axios from 'axios';
import {
    FETCH_GAME_SESSION_REQUEST,
    FETCH_GAME_SESSION_SUCCESS,
    FETCH_GAME_SESSION_FAILURE,
    LEAVE_GAME_SESSION_REQUEST,
    LEAVE_GAME_SESSION_SUCCESS,
    LEAVE_GAME_SESSION_FAILURE,
    FETCH_GAME_MESSAGES_REQUEST,
    FETCH_GAME_MESSAGES_SUCCESS,
    FETCH_GAME_MESSAGES_FAILURE,
    SEND_GAME_MESSAGE_REQUEST,
    SEND_GAME_MESSAGE_SUCCESS,
    SEND_GAME_MESSAGE_FAILURE,
} from '../constants/gameSessionConstants';

export const BASE_URL = 'https://inviders.kitezhgrad.fun/api';

// Получение данных игровой сессии
export const fetchGameSession = (gameId) => async (dispatch) => {
    try {
        dispatch({ type: FETCH_GAME_SESSION_REQUEST });

        const { data } = await axios.get(`${BASE_URL}/games/${gameId}`);

        dispatch({
            type: FETCH_GAME_SESSION_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: FETCH_GAME_SESSION_FAILURE,
            payload: error.response?.data?.error || 'Ошибка при загрузке игровой сессии',
        });
    }
};

// Покинуть игровую сессию
export const leaveGameSession = (gameId, chatId) => async (dispatch) => {
    try {
        dispatch({ type: LEAVE_GAME_SESSION_REQUEST });

        await axios.post(`${BASE_URL}/games/${gameId}/leave`, { chat_id: chatId });

        dispatch({
            type: LEAVE_GAME_SESSION_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: LEAVE_GAME_SESSION_FAILURE,
            payload: error.response?.data?.error || 'Ошибка при выходе из игровой сессии',
        });
    }
};

// Получение сообщений игрового чата
export const fetchGameMessages = (gameId) => async (dispatch) => {
    try {
        dispatch({ type: FETCH_GAME_MESSAGES_REQUEST });

        const { data } = await axios.get(`${BASE_URL}/games/${gameId}/messages`);

        dispatch({
            type: FETCH_GAME_MESSAGES_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: FETCH_GAME_MESSAGES_FAILURE,
            payload: error.response?.data?.error || 'Ошибка при загрузке сообщений',
        });
    }
};

// Отправка сообщения в игровой чат
export const sendGameMessage = (gameId, chatId, message) => async (dispatch) => {
    try {
        dispatch({ type: SEND_GAME_MESSAGE_REQUEST });

        const { data } = await axios.post(`${BASE_URL}/games/${gameId}/messages`, {
            chat_id: chatId,
            message,
        });

        dispatch({
            type: SEND_GAME_MESSAGE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: SEND_GAME_MESSAGE_FAILURE,
            payload: error.response?.data?.error || 'Ошибка при отправке сообщения',
        });
    }
};