// src/components/GameSession/CurrentTurn.js

import React from 'react';
import './CurrentTurn.css';

const CurrentTurn = ({ currentUser, nextUser }) => {
    return (
        <div className="current-turn">
            <span>Ходит: <strong>{currentUser}</strong></span>
            <span className="current-turn__next">Следующий: {nextUser}</span>
        </div>
    );
};

export default CurrentTurn;
