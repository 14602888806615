// src/components/GameRooms/RoomDetail/RoomInfo.js

import React from 'react';
import texts from '../../../utils/texts';
import './RoomInfo.css';

const RoomInfo = ({ room }) => {
    return (
        <div className="room-info">
            <p className="room-info__item">
                <strong>{texts.roomDetail.mapSize}:</strong> {room.map_size}x{room.map_size}
            </p>
            <p className="room-info__item">
                <strong>{texts.roomDetail.movesPerPlayer}:</strong> {room.moves_per_player}
            </p>
            <p className="room-info__item">
                <strong>{texts.roomDetail.status}:</strong> {room.is_open ? texts.roomDetail.status_open : texts.roomDetail.status_private}
            </p>
        </div>
    );
};

export default RoomInfo;