// src/actions/userActions.js

import axios from 'axios';
import {
    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAILURE,
    FETCH_USER_STATUS_REQUEST,
    FETCH_USER_STATUS_SUCCESS,
    FETCH_USER_STATUS_FAILURE,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE
} from '../constants/userConstants';

// Замените на ваш реальный базовый URL
export const BASE_URL = 'https://inviders.kitezhgrad.fun/api';

// Login action (for gamename and password)
export const login = (gamename, password) => async (dispatch) => {
    try {
        dispatch({type: LOGIN_REQUEST});

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        const {data} = await axios.post(
            `${BASE_URL}/users/login`,
            {gamename, password},
            config
        );

        dispatch({
            type: LOGIN_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: LOGIN_FAILURE, // Изменено с LOGIN_FAIL
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.message,
        });
    }
};

// Fetch user status via chatId (for Telegram login)
export const fetchUserStatus = (chatId) => async (dispatch) => {
    try {
        dispatch({type: FETCH_USER_STATUS_REQUEST});

        const {data} = await axios.get(`${BASE_URL}/users/${chatId}`);

        dispatch({
            type: FETCH_USER_STATUS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: FETCH_USER_STATUS_FAILURE, // Изменено с FETCH_USER_STATUS_FAIL
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.message,
        });
    }
};

// Update user action
export const updateUser = (chatId, updates) => async (dispatch) => {
    try {
        dispatch({type: UPDATE_USER_REQUEST});

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        const {data} = await axios.put(`${BASE_URL}/users/${chatId}`, updates, config);

        dispatch({
            type: UPDATE_USER_SUCCESS,
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: UPDATE_USER_FAILURE, // Изменено с UPDATE_USER_FAIL
            payload: error.response && error.response.data.error
                ? error.response.data.error
                : error.message,
        });
    }
};

// // Обновление статуса бана бота
// export const updateIsBotBanned = (chatId, isBanned) => async (dispatch) => {
//     try {
//         dispatch({ type: UPDATE_IS_BOT_BANNED_REQUEST });
//
//         const config = {
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//         };
//
//         const { data } = await axios.put(`${BASE_URL}/users/${chatId}/bot-banned`, { is_bot_banned: isBanned }, config);
//
//         dispatch({
//             type: UPDATE_IS_BOT_BANNED_SUCCESS,
//             payload: data,
//         });
//
//     } catch (error) {
//         dispatch({
//             type: UPDATE_IS_BOT_BANNED_FAILURE,
//             payload: error.response && error.response.data.error
//                 ? error.response.data.error
//                 : error.message,
//         });
//     }
// };

// Экшен для получения данных другого пользователя
export const fetchViewedUser = (gamename) => async (dispatch) => {
    dispatch({type: 'FETCH_VIEWED_USER_REQUEST'});
    try {
        const response = await axios.get(`${BASE_URL}/users/gamename/${gamename}`);
        dispatch({type: 'FETCH_VIEWED_USER_SUCCESS', payload: response.data});
    } catch (error) {
        dispatch({
            type: 'FETCH_VIEWED_USER_FAILURE',
            payload: error.response?.data?.error || 'Ошибка получения данных пользователя'
        });
    }
};
