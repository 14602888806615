// src/components/GameRooms/RoomDetail/RoomDetail.js

import React, { useEffect, useState, useRef, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchRoomDetails,
    leaveRoom,
    updateReadyStatus,
    kickPlayer,
    startGame,
    playerJoined,
    playerLeft,
    playerKicked,
    userKicked
} from '../../../actions/gameRoomActions';
import { useParams, useNavigate } from 'react-router-dom';
import { SocketContext } from '../../../contexts/SocketContext';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import RoomInfo from './RoomInfo';
import PlayerList from './PlayerList';
import Controls from './Controls';
import Chat from './Chat';
import texts from '../../../utils/texts';

const RoomDetail = () => {
    const socket = useContext(SocketContext);
    const { room_id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { currentRoom, loading, error } = useSelector((state) => state.gameRooms);
    const { user } = useSelector((state) => state.currentUser);

    const [chatMessages, setChatMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const messagesEndRef = useRef(null);

    useEffect(() => {
        if (room_id) {
            dispatch(fetchRoomDetails(room_id));
        }
    }, [dispatch, room_id]);

    useEffect(() => {
        if (!user?.chat_id) return;

        const handlePreviousMessages = (messages) => {
            setChatMessages(messages);
        };

        const handleNewMessage = (message) => {
            setChatMessages((prevMessages) => [...prevMessages, message]);
        };

        const handlePlayerJoinedEvent = (player) => {
            dispatch(playerJoined(player));
            const message = `${player.title} ${player.gamename} теперь в комнате.`;
            setChatMessages(prev => [...prev, { message, sender_name: 'Система', system: true }]);
        };

        const handlePlayerLeftEvent = (player) => {
            dispatch(playerLeft(player));
            const message = `${player.title} ${player.gamename} покинул(а) комнату.`;
            setChatMessages(prev => [...prev, { message, sender_name: 'Система', system: true }]);
        };

        const handlePlayerKickedEvent = (player) => {
            dispatch(playerKicked(player));
            const message = `${player.title} ${player.gamename} был(а) выкинут(а).`;
            setChatMessages(prev => [...prev, { message, sender_name: 'Система', system: true }]);
        };

        const handleKicked = () => {
            dispatch(userKicked());
            navigate('/mainmenu');
        };

        const handleError = (err) => {
            console.error('Socket error:', err);
        };

        const handleConnect = () => {
            // Как только подключились - вступаем в комнату
            socket.emit('joinRoom', { roomId: room_id, chatId: user.chat_id });
        };

        const handleDisconnect = () => {
            // Можно что-то сделать при дисконнекте
        };

        // Назначаем обработчики
        socket.on('connect', handleConnect);
        socket.on('previousMessages', handlePreviousMessages);
        socket.on('newMessage', handleNewMessage);
        socket.on('playerJoined', handlePlayerJoinedEvent);
        socket.on('playerLeft', handlePlayerLeftEvent);
        socket.on('playerKicked', handlePlayerKickedEvent);
        socket.on('kicked', handleKicked);
        socket.on('error', handleError);
        socket.on('disconnect', handleDisconnect);

        // Если уже подключены, то сразу joinRoom
        if (socket.connected) {
            socket.emit('joinRoom', { roomId: room_id, chatId: user.chat_id });
        }

        return () => {
            socket.off('connect', handleConnect);
            socket.off('previousMessages', handlePreviousMessages);
            socket.off('newMessage', handleNewMessage);
            socket.off('playerJoined', handlePlayerJoinedEvent);
            socket.off('playerLeft', handlePlayerLeftEvent);
            socket.off('playerKicked', handlePlayerKickedEvent);
            socket.off('kicked', handleKicked);
            socket.off('error', handleError);
            socket.off('disconnect', handleDisconnect);

            socket.emit('leaveRoom', { roomId: room_id, chatId: user.chat_id });
        };
    }, [socket, room_id, user, dispatch, navigate]);

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [chatMessages]);

    const handleLeaveRoom = async () => {
        try {
            await dispatch(leaveRoom(room_id));
            navigate('/mainmenu');
        } catch (err) {
            console.error('Ошибка при покидании комнаты:', err);
        }
    };

    const handleToggleReady = async () => {
        try {
            await dispatch(updateReadyStatus(room_id, !user.is_ready));
            dispatch(fetchRoomDetails(room_id));
        } catch (err) {
            console.error('Ошибка при обновлении статуса готовности:', err);
        }
    };

    const handleKickPlayer = async (chatId) => {
        try {
            await dispatch(kickPlayer(room_id, chatId, user.chat_id));
            dispatch(fetchRoomDetails(room_id));
        } catch (err) {
            console.error('Ошибка при выгнании игрока:', err);
        }
    };

    const handleStartGame = async () => {
        try {
            await dispatch(startGame(room_id));
        } catch (err) {
            console.error('Ошибка при запуске игры:', err);
        }
    };

    const handleSendMessage = () => {
        if (!socket) {
            console.error('Socket not connected');
            return;
        }

        if (newMessage.trim() === '') return;

        const messageData = {
            roomId: room_id,
            chatId: user.chat_id,
            message: newMessage.trim(),
        };

        socket.emit('sendMessage', messageData, (acknowledgment) => {
            if (acknowledgment && acknowledgment.error) {
                console.error('Error sending message:', acknowledgment.error);
            }
        });

        setNewMessage('');
    };

    if (loading) {
        return <LoadingSpinner />;
    }

    if (error) {
        return <p className="room-detail__error">{error}</p>;
    }

    if (!currentRoom) {
        return <p className="room-detail__error">Комната не найдена.</p>;
    }

    // Проверка, находится ли пользователь в комнате
    const isPlayerInRoom = Array.isArray(currentRoom.players) &&
        currentRoom.players.some(player => player.chat_id === user.chat_id);

    if (!isPlayerInRoom) {
        return (
            <div className="room-detail__not-in-room">
                <p>{texts.roomDetail.youAreNotInRoom}</p>
                <button className="index__button" onClick={() => navigate('/mainmenu')}>Назад</button>
            </div>
        );
    }

    const isHost = currentRoom.host_chat_id === user.chat_id;
    const allReady =
        Array.isArray(currentRoom.players) && currentRoom.players.every((p) => p.is_ready);

    return (
        <div className="room-detail">
            <h1 className="room-detail__title">
                {texts.roomDetail.title} {currentRoom.room_name}
            </h1>
            <RoomInfo room={currentRoom} />
            <PlayerList
                players={currentRoom.players}
                maxPlayers={currentRoom.max_players}
                isHost={isHost}
                currentUser={user}
                onKickPlayer={handleKickPlayer}
            />
            <Controls
                isHost={isHost}
                allReady={allReady}
                userIsReady={user.is_ready}
                onToggleReady={handleToggleReady}
                onStartGame={handleStartGame}
                onLeaveRoom={handleLeaveRoom}
            />
            <Chat
                messages={chatMessages}
                newMessage={newMessage}
                onSendMessage={handleSendMessage}
                onChangeMessage={setNewMessage}
                messagesEndRef={messagesEndRef}
            />
            <div ref={messagesEndRef} />
        </div>
    );
};

export default RoomDetail;