// src/components/GameSession/GameChat.js

import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendGameMessage, fetchGameMessages } from '../../actions/gameSessionActions';
import './GameChat.css';

const GameChat = ({ gameId }) => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.currentUser);
    const { messages } = useSelector((state) => state.gameSession);

    const [newMessage, setNewMessage] = useState('');
    const messagesEndRef = useRef(null);

    useEffect(() => {
        if (gameId) {
            dispatch(fetchGameMessages(gameId));
        }
    }, [dispatch, gameId]);

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleSendMessage = () => {
        if (newMessage.trim() === '') return;
        dispatch(sendGameMessage(gameId, user.chat_id, newMessage.trim()));
        setNewMessage('');
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSendMessage();
        }
    };

    return (
        <div className="game-chat">
            <div className="game-chat__messages">
                {messages.map((msg) => (
                    <div key={msg.id} className="game-chat__message">
                        <strong>{msg.sender_name}:</strong> {msg.message}
                    </div>
                ))}
                <div ref={messagesEndRef} />
            </div>
            <div className="game-chat__input">
                <input
                    type="text"
                    className="game-chat__text-input"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    placeholder="Введите сообщение..."
                    onKeyDown={handleKeyDown}
                />
                <button className="game-chat__send-button" onClick={handleSendMessage}>
                    &gt;
                </button>
            </div>
        </div>
    );
};

export default GameChat;