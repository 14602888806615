// src/components/GameRooms/RoomDetail/PlayerList.js

import React from 'react';
import texts from '../../../utils/texts';
import PlayerItem from './PlayerItem';
import './PlayerList.css';

const PlayerList = ({ players, maxPlayers, isHost, currentUser, onKickPlayer }) => {
    return (
        <div className="player-list">
            {Array.isArray(players) ? (
                <ul className="player-list__list">
                    {players.map((player) => (
                        <PlayerItem
                            key={player.chat_id}
                            player={player}
                            isHost={isHost}
                            currentUser={currentUser}
                            onKickPlayer={onKickPlayer}
                        />
                    ))}
                    {Array.from({ length: maxPlayers - players.length }).map((_, index) => (
                        <li key={`empty-${index}`} className="player-list__item player-list__item--empty">
                            {texts.roomDetail.slot}
                        </li>
                    ))}
                </ul>
            ) : (
                <p>{texts.roomDetail.loadingGamers}</p>
            )}
        </div>
    );
};

export default PlayerList;