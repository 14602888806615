// src/utils/cells.js

const CELLS = {
    grass: {
        color: '#315f3c',
        rarity: 0.55,
        requiredAttack: 0,
        points: 0,
        name: "трава",
        description: "Трава — стандартный тип клетки.",
    },
    water: {
        color: 'darkblue',
        rarity: 0.08,
        name: "вода",
        description: "Вода — непроходимая клетка.",
    },
};

export default CELLS;
